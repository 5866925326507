import React, { Component } from 'react';
import DonorHomePage from '../Modules/Pages/Donor/DonorHomePage/DonorHomePage';
import { MAIN_CONFIG } from '../config/main';
import Custom404Page from './404';
import ReviewerHomePage from '../Modules/Pages/Reviewer/ReviewerHomePage/ReviewerHomePage';
import RecommenderHomePage from '../Modules/Pages/RecommenderHomePage/RecommenderHomePage';
import ApplicantHomePage from '../Modules/Pages/Applicant/ApplicantHomePage/ApplicantHomePage';
const { USER_CONTEXT } = MAIN_CONFIG;

export default class HomeContainer extends Component {

    // componentDidMount() {
    //     if(process.env.NEXT_PUBLIC_BEACON_KEY) {
    //         window.Beacon('init', process.env.NEXT_PUBLIC_BEACON_KEY)
    //     }
    // }

    static async getInitialProps (context) {
        return DonorHomePage.getInitialProps(context, MAIN_CONFIG.PAGE_TYPES.DONOR.HOME);
    }

    render () {
        const { userContext } = this.props;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                return  this.props.pageData && this.props.pageData.account === false ? <Custom404Page { ...this.props } message="Your account is not ready, please contact your administrator." /> : <DonorHomePage { ...this.props } />;
            case USER_CONTEXT.REVIEWER:
                return  this.props.pageData && this.props.pageData.account === false ? <Custom404Page { ...this.props } message="Your account is not ready, please contact your administrator." /> : <ReviewerHomePage { ...this.props } />;
            case USER_CONTEXT.RECOMMENDER:
            case USER_CONTEXT.NGB:
            case USER_CONTEXT.SCHOOLCOUNSELLOR:
            case USER_CONTEXT.ENDORSEDUSER:
            case USER_CONTEXT.THIRDPARTYCONTRIBUTOR:
                return <RecommenderHomePage { ...this.props } />;
            case USER_CONTEXT.APPLICANT:
            case USER_CONTEXT.TEAMMEMBER:
            case USER_CONTEXT.LOGGED_OUT:
                return <ApplicantHomePage { ...this.props } />
        }

    }
}

